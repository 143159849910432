import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import ReactGA from "react-ga4"
import { ThemeProvider } from "@allied/react-web/Theme"
import { MetaProvider } from "@allied/react-web/Meta"
import { SiteProvider } from "../Site"
import { routes } from "../../routes"
import "./App.css"

ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const meta = {
  titleTemplate: "ICH Inland Container Depot Ltd | %s",
  titleFallback: "ICH Inland Container Depot Ltd",
  descriptionFallback: "ICH Inland Container Depot was incorporated in Yangon in 2017. The company provide Lift On/Lift Off facilities, pre-trip inspection (PTI), container storage, container survey, container repair and container washing services to our customers. ICH Inland Container Depot operates on 10 acres of land and can hold up to 9,000 TEUs. We have invested in the most up-to-date forklift to provide the highest level of service. We operate 24/7 to provide the highest service to our customers.",
  keywordsFallback: "acds, logistic, supply, chain, management, business, solution, efficient, technology, ich, inland, container, depot, yangon, myanmar, allied, container, singapore",
  typeFallback: "website",
  urlFallback: window.location.href,
  imageFallback: `${process.env.REACT_APP_PUBLIC_URL}/image/acdsich-meta.webp`,
  markupSchemaFallback: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "ICH Inland Container Depot Ltd",
    "image": "https://ichdepot.com/image/acdsich-meta.webp",
    "@id": "https://ichdepot.com/",
    "url": "https://ichdepot.com/",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Sittaung Road, Dagon Seikkan Dockyard",
      "addressLocality": "Myanmar",
      "postalCode": "11441",
      "addressCountry": "MM"
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "08:30",
      "closes": "17:30"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 16.8137426,
      "longitude": 96.2496832
    },
    "sameAs": [
      "https://www.facebook.com/AlliedContainerGroup",
      "https://www.linkedin.com/company/allied-container-group/",
    ]
  },
  twitterSchemaFallback: {
    "card": "summary_large_image",
  }
}
export default function App(): React.JSX.Element {
  return (
    <SiteProvider host={window.location.host}>
      <ThemeProvider theme="acds">
        <MetaProvider meta={meta}>
          <Router>
            <Routes>
              {
                routes.map((route, i) => {
                  return <Route {...route} key={`route-item-${i}`} />
                })
              }
            </Routes>
          </Router>
        </MetaProvider>
      </ThemeProvider>
    </SiteProvider>
  )
}
